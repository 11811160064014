<template>
  <div>
    <b-form-row>
      <b-col>
        <b-form-group>
          <label>Heading text (Optional)</label>
          <div class="floating-input-field">
            <b-form-textarea
              id="calendar-heading-text"
              placeholder=" "
              class="overflow-auto"
              rows="5"
              v-model="widgetDetails.calendarWidgetSettings.headingTxt"
            ></b-form-textarea>
            <label for="calendar-heading-text">Heading text</label>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <label>Calendar categories title (Optional)</label>
          <div class="floating-input-field">
            <b-form-textarea
              id="calendar-categories-title"
              placeholder=" "
              class="overflow-auto"
              rows="5"
              v-model="widgetDetails.calendarWidgetSettings.categoryHeadingTxt"
            ></b-form-textarea>
            <label for="calendar-categories-title">Calendar categories title</label>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="d-flex mb-2">
        <div>
          <InfoCircelYellow class="mr-1 mt-n1" />
        </div>
        <div class="font-14">
          {{ DISPLAY_MESSAGES.CALENDAR_FILTER_BY_LOCATION_INFO }}
        </div>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12">
        <b-form-group>
          <b-form-checkbox
            id="calendar-filter-by-location"
            name="calendar-filter-by-location"
            :switch="true"
            :checked="widgetDetails.calendarWidgetSettings.isLocationEnabled"
            v-model="widgetDetails.calendarWidgetSettings.isLocationEnabled"
          >
            Filter by location
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="widgetDetails.calendarWidgetSettings.isLocationEnabled">
      <b-col>
        <b-form-group>
          <label>Calendar locations title (Optional)</label>
          <div class="floating-input-field">
            <b-form-textarea
              id="calendar-locations-title"
              placeholder=" "
              class="overflow-auto"
              rows="5"
              v-model="widgetDetails.calendarWidgetSettings.locationHeadingTxt"
            ></b-form-textarea>
            <label for="calendar-locations-title">Calendar locations title</label>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import InfoCircelYellow from '@/assets/svg/info-circle-yellow.svg'
import { DISPLAY_MESSAGES } from '@/utilities/constants'
export default {
  name: 'CalendarWidgetSettings',
  props: ['widgetDetails'],
  components: {
    InfoCircelYellow,
  },
  data() {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
}
</script>
